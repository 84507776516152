import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Strona nie istnieje" />
    <h1>404 - Strona nie istnieje</h1>
    <a href="/">Wróć na stronę główną</a>
  </Layout>
)

export default NotFoundPage
